import { ScrollSpy, Collapse } from 'bootstrap';
import SmoothScroll from 'smooth-scroll';

$(document).ready(() => {

    // Navigation Bar

    const collapse = new Collapse(document.querySelector('.navbar-collapse'), { toggle: false });
    $('.navbar-brand, .nav-link').click((e) => { collapse.hide(); });

    const navbarCollapse = () => {
        const $nav = $('#navigation');
        if ($nav.offset().top > 100) {
            $nav.addClass('navbar-shrink');
        } else {
            $nav.removeClass('navbar-shrink');
        }
    }

    navbarCollapse();
    $(window).scroll(navbarCollapse);

    const scrollSpy = new ScrollSpy(document.body, { target: '#navigation', offset: 56 });

    // Smooth Scroll 

    const scroll = new SmoothScroll('a[href*="#"]', { speed: 250, offset: 56 });
    document.addEventListener('scrollStart', (event) => {
        if (event.detail && event.detail.anchor && event.detail.anchor.id) {
            const anchor = event.detail.anchor;
            const toggle = event.detail.toggle;
            const id = anchor.id;
            if (id.includes('become') && $(anchor).is(':hidden')) {
                event.preventDefault();
                $(anchor).slideDown('fast', () => {
                    scroll.animateScroll(anchor, toggle);
                });
            }
        }
    }, false);
});